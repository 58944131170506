<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col>
          <h2>Бүтээгдэхүүний өртөг</h2>
        </el-col>
      </el-row>
    </header>
      <div class="panel tabled">
        <el-tabs>
          <el-tab-pane label="Бүгд">
            <div class="panel-content">
              <div class="pl20 pr20 table-filter mb10">
                <el-row :gutter="10" align="middle" type="flex">
                  <!-- <el-col :span="10">
                    <el-input v-model="search" placeholder="Бараа хайх" class="input-with-select">
                      <el-button slot="prepend" icon="el-icon-search"></el-button>
                    </el-input>
                  </el-col> -->
                  <el-col :span="24" class="text-left">
                    <el-select clearable filterable placeholder="Нийлүүлэгч сонгох" v-model="supplier_id" @change="getProductPrice" class="mr30">
                      <el-option
                        v-for="(supplier, index) in suppliers"
                        :key="index"
                        :label="supplier.supplier_monName"
                        :value="supplier.supplier_id">
                      </el-option>
                    </el-select>
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="start_date"
                      @change="getProductPrice"
                      type="datetime"
                      placeholder="Эхлэх огноо сонгох">
                    </el-date-picker>
                    -
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @change="getProductPrice"
                      v-model="end_date"
                      type="datetime"
                      placeholder="Дуусах огноо сонгох">
                    </el-date-picker>
                  </el-col>
                   <!-- <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'firstChar'>
                              Нэр А - Я
                           </el-dropdown-item>
                          <el-dropdown-item command = 'lastChar'>
                              Нэр Я - А
                          </el-dropdown-item>
                          <el-dropdown-item command = 'newest'>
                              Шинэ эхэнд
                          </el-dropdown-item>
                          <el-dropdown-item command = 'oldest'>
                              Хуучин эхэнд
                          </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col> -->
                </el-row>
                <el-table
                v-loading="loading"
                :data="productData"
                style="width: 100%"
                class="mt20">
                  <el-table-column
                    prop="supplier_name"
                    label="Нийлүүлэгч">
                  </el-table-column>
                  <el-table-column
                    prop="product_name"
                    label="Бүтээгдэхүүн">
                  </el-table-column>
                  <el-table-column
                    prop="supplier_diamond_code"
                    label="Даймонд код">
                  </el-table-column>
                  <el-table-column
                    label="Эхний үлдэгдэл" align="center">
                    <el-table-column
                      prop="ehnii_uldegdel"
                      label="Тоо хэмжээ">
                    </el-table-column>
                    <el-table-column
                      prop="ehnii_ortog"
                      label="Өртөг">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    label="Орлого" align="center">
                    <el-table-column
                      prop="orlogo_too"
                      label="Тоо хэмжээ">
                    </el-table-column>
                    <el-table-column
                      prop="orlogo_ortogo"
                      label="Өртөг">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    label="Зарлага" align="center">
                    <el-table-column
                      prop="zarlaga_too"
                      label="Тоо хэмжээ">
                    </el-table-column>
                    <el-table-column
                      prop="zarlaga_ortogo"
                      label="Өртөг">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    label="Эцсийн" align="center">
                    <el-table-column
                      prop="etssiin_uldegdel"
                      label="Үлдэгдэл">
                    </el-table-column>
                    <el-table-column
                      prop="etssiin_ortog"
                      label="Өртөг">
                    </el-table-column>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change = "curentPageHandler"
                  @size-change = "sizeChangeHandler"
                  class="text-right mt10"
                  background
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  :page-sizes="[5, 20, 50, 100]"
                  layout="total, sizes, prev, pager, next"
                  :total="totalCount">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      from: '',
      size: '',
      loading: false,
      search: '',
      start_date: '',
      end_date: '',
      supplier_id: '',
      productData: [],
      suppliers: [],
      totalCount: 0,
      pageSize: 20,
      currentPage: 1
    }
  },

  mounted () {
    this.getSuppliers()
  },

  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'cost' })
      this.getProductPrice()
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'cost' })
      this.getProductPrice()
    },

    getProductPrice () {
      const tempFrom = this.pageSize * (this.currentPage - 1)
      const body = {
        from: tempFrom,
        size: this.pageSize,
        start_date: this.start_date,
        end_date: this.end_date,
        supplier_id: this.supplier_id
      }
      if (this.start_date !== '' && this.end_date !== '') {
        this.loading = true
        services.getProductPrice(body).then(response => {
          if (response.status === 'success') {
            this.productData = response.data
            this.totalCount = response.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },

    getSuppliers () {
      const query = '?search_text=' + this.search
      services.getSuppliers(query).then(response => {
        this.suppliers = response.datas
      })
    }
  }
}
</script>

<style>
</style>
